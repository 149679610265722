import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import { Footer } from "../components/Footer/index";
import { graphql, StaticQuery } from "gatsby";
import AmssLogoImg from "../images/amsslogo.png";
import { MemberModal } from "../components/Modal/index";
import ApiCaller from "../core/Api";
import { useNavigate } from "@reach/router";
import Bg from "../images/member/background1.svg";

const Login = () => {
  const [showModal, setShowModal] = useState(false);
  const [mobile, setMobile] = useState(null);
  const [otp, setOTP] = useState("unready");
  const [votp, setVOTP] = useState("unready");
  const navigate = useNavigate();
  const handleGetOTP = () => {
    mobile && ApiCaller.getOTP(mobile).then((res) => setOTP(res.code));
  };

  const handleLogin = () => {
    if (otp === votp) {
      ApiCaller.login(mobile).then((res) => navigate("/member"));
    } else {
      alert("验证码错误");
    }
  };

  return (
    <>
      <MemberModal open={showModal} setOpen={setShowModal} />
      <InputWrap onChange={(e) => setMobile(e.target.value)} placeholder="手机号：" style={{ marginTop: "75px" }} />
      <div style={{ width: "390px" }}>
        <InputWrap type="password" onChange={(e) => setVOTP(e.target.value)} style={{ width: "163px" }} />
        <Button1 onClick={handleGetOTP}>获取验证码</Button1>
      </div>
      <EnterButton onClick={handleLogin} style={{ marginTop: "48px" }}>
        进入
      </EnterButton>
      <FooterText
        onClick={() => {
          setShowModal(true);
        }}
      >
        加入会员 Sign in
      </FooterText>
    </>
  );
};

const Enter = ({ setShowLogin }) => (
  <>
    <div style={{ marginTop: "30px" }}>
      <EnterText>大宗资产业主与资方的信息对接平台</EnterText>
      <EnterSubText>Asset Owner vs. Investor Info-X Platform</EnterSubText>
    </div>
    <UL>
      <LI>
        <div>
          <EnterText>筛选项目发布</EnterText>
          <EnterSubText>Shortlisted Projects</EnterSubText>
        </div>
      </LI>
      <LI>
        <div>
          <EnterText>项目相关大数据分析</EnterText>
          <EnterSubText>Project BigData Analysis</EnterSubText>
        </div>
      </LI>
      <LI>
        <div>
          <EnterText>项目免费一键估值/DCF服务</EnterText>
          <EnterSubText>Project Valuation & DCF</EnterSubText>
        </div>
      </LI>
    </UL>
    <EnterButton onClick={() => setShowLogin(true)} style={{ marginTop: "60px" }}>
      进入
    </EnterButton>
  </>
);

export default () => {
  const [showLogin, setShowLogin] = useState(false);
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "strategy/bg.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2480) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <Layout>
            <Container bg={Bg}>
              <LoginWrap>
                <AmssLogo src={AmssLogoImg} alt="" />
                <p className="title">AMSS会员项目对接专区</p>
                <p className="sub-title">Member Off-Market Deal-Link Zone</p>
                <div className="line"></div>
                {showLogin ? <Login /> : <Enter setShowLogin={setShowLogin} />}
              </LoginWrap>
            </Container>
            <Footer />
          </Layout>
        );
      }}
    />
  );
};

const LoginWrap = styled.div`
  margin-top: 140px;
  margin-bottom: 70px;
  width: 500px;
  height: 682px;
  border-radius: 10px;
  background-color: rgb(47, 56, 82, 0.85);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  p {
    line-height: 1.2;
    font-weight: 400;
    color: #fff;
  }
  .title {
    font-size: 30px;
  }
  .sub-title {
    font-size: 18px;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin: 12px 0;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: ${(props) => `url(${props.bg})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

const AmssLogo = styled.img`
  width: 60px;
  margin: 15px 0;
`;

const InputWrap = styled.input`
  width: 390px;
  height: 45px;
  background: none;
  border: solid 1px #ffffff;
  border-radius: 6px;
  color: #fff;
  padding: 5px;
  margin-top: 42px;
  &::placeholder {
    color: #fff;
    opacity: 1; /* Firefox */
  }
`;

const Button1 = styled.button`
  width: 102px;
  height: 32px;
  border-radius: 6px;
  background-color: #ffffff;
  margin-left: 25px;
  cursor: pointer;
`;

const LoginButton = styled.button`
  width: 120px;
  height: 50px;
  border-radius: 25px;
  background-color: #ffffff;
  margin-top: 47px;
  cursor: pointer;
`;

const FooterText = styled.div`
  width: 127px;
  height: 28px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  text-align: center;
  color: #ffffff;
  border-bottom: 1px solid #fff;
  margin-top: 128px;
  cursor: pointer;
`;

const EnterText = styled.p`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.35px;
  text-align: center;
  color: #ffffff;
`;

const EnterSubText = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 2.33;
  letter-spacing: 0.27px;
  text-align: center;
  color: #ffffff;
`;

const UL = styled.ul`
  list-style: none;
`;
const LI = styled.li`
  position: relative;
  margin-top: 30px;
  padding-left: 28px;
  p {
    text-align: left;
  }
  &:before {
    position: absolute;
    content: '■';
    color: #fff;
    font-size: 16px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const EnterButton = styled.button`
  width: 120px;
  height: 50px;
  border-radius: 25px;
  border: solid 2px #ffffff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color: #4f4f4f;
  }
`;
