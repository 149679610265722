import React from "react";
import styled, { keyframes } from "styled-components";
import Qr from "../../images/qrcode/qrcodeamss.jpg";
import QrAssistant from "../../images/qrcode/qrcodeassistant.png";

export const Modal = ({ message, open, setOpen, cb }) => {
  const handleClick = () => {
    cb && cb();
    setOpen((v) => !v);
  };
  return (
    <Container open={open}>
      <Mask />
      <Wrap>
        <div onClick={handleClick} className="close">
          X
        </div>
        <Qrcode src={QrAssistant} alt="" />
        <p className="title">
          升级为客户级会员 <br />
          请联系晤桥助手
        </p>
      </Wrap>
    </Container>
  );
};

export const MemberModal = ({ open, setOpen, cb }) => {
  const handleClick = () => {
    cb && cb();
    setOpen((v) => !v);
  };
  return (
    <Container open={open}>
      <Mask />
      <Wrap>
        <div onClick={handleClick} className="close">
          X
        </div>
        <Qrcode src={Qr} alt="" />
        <p className="title">
          请扫码填写资料 <br />
          加入AMSS会员
        </p>
      </Wrap>
    </Container>
  );
};

export const Modal2 = ({ message, open, setOpen, cb }) => {
  const handleClick = () => {
    cb && cb();
    setOpen((v) => !v);
  };
  return (
    <Container open={open}>
      <Mask />
      <Wrap>
        <div onClick={handleClick} className="close">
          X
        </div>
        <Qrcode src={Qr} alt="" />
        <p className="title">
          请扫码填写资料 <br />
          加入AMSS会员
        </p>
      </Wrap>
    </Container>
  );
};

const Qrcode = styled.img`
  width: 150px;
  height: 150px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.open ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const Wrap = styled.div`
  width: 250px;
  height: 365px;
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 30px;
  flex-direction: column;
  .close {
    position: absolute;
    color: #dadada;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  .title {
    font-size: 16px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.35px;
    text-align: center;
    color: #2f3852;
  }
`;

const Mask = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.6;
  top: 0;
  left: 0;
`;

const Header = styled.div`
  text-align: center;
  width: 100%;
  height: 40px;
  font-size: 26px;
  color: ${(props) => props.theme.mainColor};
`;

const Message = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.darkFontColor};
  font-size: 18px;
`;

const ModalLoading = keyframes`
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }  `;

const Loader = styled.span`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #888;
  border-left-color: transparent;
  border-top-color: transparent;
  animation: ${ModalLoading} 1s linear infinite;
`;
